@use "../../abstracts/bs-custom";

.jsgrid-table{

    .jsgrid-header-row{

        & > .jsgrid-header-cell{
            color: bs-custom.$dark;
        }
    }

    & > tbody > tr > td{
        padding: 1.2em;
    }

    .jsgrid-insert-row, .jsgrid-edit-row{

        input, select{
            border: 0.0625rem solid #DDDFE1;
        }
    }
}

.jsgrid .jsgrid-button{
    border: 0 !important;
    margin-left: 0.625rem;
}
