@use "functions";
@use "mixins/border-radius";
@use "variables";
@use "vendor/rfs";

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: variables.$breadcrumb-padding-y variables.$breadcrumb-padding-x;
  margin-bottom: variables.$breadcrumb-margin-bottom;
  @include rfs.font-size(variables.$breadcrumb-font-size);
  list-style: none;
  background-color: variables.$breadcrumb-bg;
  @include border-radius.border-radius(variables.$breadcrumb-border-radius);
}

.breadcrumb-item {
  // The separator between breadcrumbs (by default, a forward-slash: "/")
  + .breadcrumb-item {
    padding-left: variables.$breadcrumb-item-padding-x;

    &::before {
      float: left; // Suppress inline spacings and underlining of the separator
      padding-right: variables.$breadcrumb-item-padding-x;
      color: variables.$breadcrumb-divider-color;
      content: var(--#{variables.$variable-prefix}breadcrumb-divider, functions.escape-svg(variables.$breadcrumb-divider)) #{"/* rtl:"} var(--#{variables.$variable-prefix}breadcrumb-divider, functions.escape-svg(variables.$breadcrumb-divider-flipped)) #{"*/"};
    }
  }

  &.active {
    color: variables.$breadcrumb-active-color;
  }
}
