@use "../../abstracts/bs-custom";
@use "../../abstracts/mixin";

.jqvmap-zoomin,
.jqvmap-zoomout {
    height: 1.25rem;
    width: 1.25rem;
    line-height: 0.875rem;
    background-color: var(--primary);
    color: bs-custom.$white;
}

.jqvmap-zoomout {
    top: 2.1875rem;
}

#world-map, #usa {
    height: 25rem;
    
	@include mixin.respond ('tab-port'){
		height:21.875rem;
	}
	@include mixin.respond ('phone'){
		height:14.375rem;
	}

}