@use "../../abstracts/bs-custom";

[data-theme-version="dark"] {
    .sidebar-right {
		.card-tabs{
			.nav-tabs{
				border-color: var(--rgba-primary-1)!important;
				.nav-item{
					.nav-link{
						color:bs-custom.$black!important;
					}
				} 
			}
		}
		.form-control{
			background:bs-custom.$white;
			color:bs-custom.$black;
			border-color:bs-custom.$border-color;
		}
		.default-select .list{
			background:bs-custom.$white;
			.option{
				&.selected,&.focus,&:hover{
					background:rgba(bs-custom.$black,0.05)!important;
				}
			}
		}
		.sidebar-right-inner>h4{
			color:bs-custom.$black!important;
		}
		.nice-select .option {
			background: #fff;
		}
    }
}