@use "sass:color";
@use "../../abstracts/maps";

//background for nav header
@each $name, $color in maps.$theme_backgrounds {
    [data-primary="#{$name}"] {
        @if $name != "color_1"  {
			--primary: #{$color};
			--primary-hover: #{color.adjust($color, $lightness: -10)};
			--primary-dark: #{color.adjust($color, $lightness: -30)};
			--rgba-primary-1: #{rgba($color, 0.1)};
			--rgba-primary-2: #{rgba($color, 0.2)};
			--rgba-primary-3: #{rgba($color, 0.3)};
			--rgba-primary-4: #{rgba($color, 0.4)};
			--rgba-primary-5: #{rgba($color, 0.5)};
			--rgba-primary-6: #{rgba($color, 0.6)};
			--rgba-primary-7: #{rgba($color, 0.7)};
			--rgba-primary-8: #{rgba($color, 0.8)};
			--rgba-primary-9: #{rgba($color, 0.9)};
		}
	}
}

@each $name, $color in maps.$theme_backgrounds {
    [data-secondary="#{$name}"] {
        @if $name != "color_1"  {
			--secondary: #{$color};
			--secondary-dark: #{color.adjust($color, $lightness: -10)};
		}
	}
}
