@use "mixins/border-radius";
@use "mixins/box-shadow";
@use "mixins/image";
@use "variables";
@use "vendor/rfs";

// Responsive images (ensure images don't scale beyond their parents)
//
// This is purposefully opt-in via an explicit class rather than being the default for all `<img>`s.
// We previously tried the "images are responsive by default" approach in Bootstrap v2,
// and abandoned it in Bootstrap v3 because it breaks lots of third-party widgets (including Google Maps)
// which weren't expecting the images within themselves to be involuntarily resized.
// See also https://github.com/twbs/bootstrap/issues/18178
.img-fluid {
  @include image.img-fluid();
}


// Image thumbnails
.img-thumbnail {
  padding: variables.$thumbnail-padding;
  background-color: variables.$thumbnail-bg;
  border: variables.$thumbnail-border-width solid variables.$thumbnail-border-color;
  @include border-radius.border-radius(variables.$thumbnail-border-radius);
  @include box-shadow.box-shadow(variables.$thumbnail-box-shadow);

  // Keep them at most 100% wide
  @include image.img-fluid();
}

//
// Figures
//

.figure {
  // Ensures the caption's text aligns with the image.
  display: inline-block;
}

.figure-img {
  margin-bottom: variables.$spacer / 2;
  line-height: 1;
}

.figure-caption {
  @include rfs.font-size(variables.$figure-caption-font-size);
  color: variables.$figure-caption-color;
}
