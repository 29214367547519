@use "sass:color";
@use "../../abstracts/bs-custom";
@use "../../abstracts/mixin";
@use "../../abstracts/variable";



.alert{
    border-radius: variable.$radius;
	padding:1rem 1.5rem;
	@include mixin.respond ('phone'){
		padding-right:3rem;
	}
	p{
		line-height:1.5;
	}
}
.alert-square{
	border-radius:0;

}

.alert-rounded{
    border-radius: 1.875rem;
}

// Basic Alert


.alert-primary{
    background: var(--rgba-primary-1);
    border-color: var(--rgba-primary-1);
    color: var(--primary);
	@at-root [data-theme-version="dark"] & {
		background: var(--rgba-primary-1);
		border-color: var(--rgba-primary-1);
	}
}

.alert-secondary{
    background: variable.$secondary-light;
    border-color: variable.$secondary-light;
    color: bs-custom.$secondary;
	@at-root [data-theme-version="dark"] & {
		background: variable.$secondary-opacity;
		border-color: variable.$secondary-opacity;
		color:bs-custom.$white;
	}
}


.alert-success{
    background: variable.$success-light;
    border-color: variable.$success-light;
    color: bs-custom.$success;
	@at-root [data-theme-version="dark"] & {
		background: variable.$success-opacity;
		border-color: variable.$success-opacity;
	}
}

.alert-warning{
    background: variable.$warning-light;
    border-color: variable.$warning-light;
    color: bs-custom.$warning;
	@at-root [data-theme-version="dark"] & {
		background: variable.$warning-opacity;
		border-color: variable.$warning-opacity;
	}
}

.alert-danger{
    background: variable.$danger-light;
    border-color: variable.$danger-light;
    color: bs-custom.$danger;
	@at-root [data-theme-version="dark"] & {
		background: variable.$danger-opacity;
		border-color: variable.$danger-opacity;
	}
}

.alert-info{
    background: color.adjust(bs-custom.$info, $lightness: 35%);
    border-color: color.adjust(bs-custom.$info, $lightness: 35%);
    color: bs-custom.$info;
	@at-root [data-theme-version="dark"] & {
		background: variable.$info-opacity;
		border-color: variable.$info-opacity;
	}
}

.alert-dark{
    background: color.adjust(bs-custom.$dark, $lightness: 50%);
    border-color: color.adjust(bs-custom.$dark, $lightness: 50%);
    color: bs-custom.$dark;
	@at-root [data-theme-version="dark"] & {
		background: variable.$dark-opacity;
		border-color: variable.$dark-opacity;
		color:bs-custom.$white;
	}
}
.alert-light{
    background: bs-custom.$light;
    border-color: bs-custom.$light;
    color: bs-custom.$dark;
	
}


// Alert alt

.alert-alt.alert-primary{
    border-left: 0.25rem solid var(--primary);
}

.alert-alt.alert-secondary{
    border-left: 0.25rem solid bs-custom.$secondary;
}


.alert-alt.alert-success{
    border-left: 0.25rem solid bs-custom.$success
}

.alert-alt.alert-warning{
    border-left: 0.25rem solid bs-custom.$warning;
}

.alert-alt.alert-danger{
    border-left: 0.25rem solid bs-custom.$danger;
}

.alert-alt.alert-info{
    border-left: 0.25rem solid bs-custom.$info;
}

.alert-alt.alert-dark{
    border-left: 0.25rem solid bs-custom.$dark;
}
.alert-alt.alert-light{
    border-left: 0.25rem solid color.adjust(bs-custom.$light,$lightness: -15%);
}



// Alert alt with solid color

.alert-alt.alert-primary.solid{
    border-left: 0.25rem solid var(--primary-dark)!important;
}

.alert-alt.alert-secondary.solid{
    border-left: 0.25rem solid color.adjust(bs-custom.$secondary,$lightness: -25%)!important;
}


.alert-alt.alert-success.solid{
    border-left: 0.25rem solid color.adjust(bs-custom.$success, $lightness: -25%)!important;
}

.alert-alt.alert-warning.solid{
    border-left: 0.25rem solid color.adjust(bs-custom.$warning,$lightness: -25%)!important;
}

.alert-alt.alert-danger.solid{
    border-left: 0.25rem solid color.adjust(bs-custom.$danger,$lightness: -25%)!important;
}

.alert-alt.alert-info.solid{
    border-left: 0.25rem solid color.adjust(bs-custom.$info,$lightness: -25%)!important;
}

.alert-alt.alert-dark.solid{
    border-left: 0.25rem solid color.adjust(bs-custom.$dark,$lightness: -25%)!important;
}
.alert-alt.alert-light.solid{
    border-left: 0.25rem solid color.adjust(bs-custom.$light,$lightness: -25%)!important;
}


//Alert dismissable with solid color
.alert-dismissible.solid{
    .close{
        &:hover{
            color: bs-custom.$white;
            opacity: 1;
        }
    }
}






// Alert Solid 
.alert.alert-primary.solid{
    background: var(--primary);
    color: bs-custom.$white;
    border-color: var(--primary);
}

.alert.alert-secondary.solid{
    background: bs-custom.$secondary;
    color: bs-custom.$white;
    border-color: bs-custom.$secondary;
}
.alert.alert-success.solid{
    background: bs-custom.$success;
    color: bs-custom.$white;
    border-color: bs-custom.$success;
}

.alert.alert-warning.solid{
    background: bs-custom.$warning;
    color: bs-custom.$white;
    border-color: bs-custom.$warning;
}

.alert.alert-danger.solid{
    background: bs-custom.$danger;
    color: bs-custom.$white;
    border-color: bs-custom.$danger;
}

.alert.alert-info.solid{
    background: bs-custom.$info;
    color: bs-custom.$white;
    border-color: bs-custom.$info;
}


.alert.alert-dark.solid{
    background: bs-custom.$dark;
    color: bs-custom.$white;
    border-color: bs-custom.$dark;
}


.alert.alert-light.solid{
    background: bs-custom.$light;
    color: bs-custom.$dark;
    border-color: bs-custom.$light;
}




// Alert right icon

.alert-right-icon{
    &>span i{
        font-size: 1.125rem;
        margin-right: 0.3125rem;
    }
    .close{
        i{
            font-size: 1rem;
        }
    }
}

// Alert Outline
.alert.alert-outline-primary{
    background: transparent;
    color: var(--primary);
    border-color: var(--primary);
}


.alert.alert-outline-secondary{
    background: transparent;
    color: bs-custom.$body-color;
    border-color: bs-custom.$secondary;
}


.alert.alert-outline-success{
    background: transparent;
    color: bs-custom.$success;
    border-color: bs-custom.$success;
}


.alert.alert-outline-info{
    background: transparent;
    color: bs-custom.$info;
    border-color: bs-custom.$info;
}


.alert.alert-outline-warning{
    background: transparent;
    color: bs-custom.$warning;
    border-color: bs-custom.$warning;
}

.alert.alert-outline-danger{
    background: transparent;
    color: bs-custom.$danger;
    border-color: bs-custom.$danger;
}

.alert.alert-outline-dark{
    background: transparent;
    color: bs-custom.$body-color;
    border-color: bs-custom.$dark;
}

.alert.alert-outline-light{
    background: transparent;
    color: bs-custom.$dark;
    border-color: bs-custom.$light;
}



// Alert Social
.alert-social{
    color: bs-custom.$white;
    .alert-social-icon{
        align-self: center;
        margin-right: 0.9375rem;
        i{
            font-size: 2.625rem;
        }
    }
    &.facebook{
        background-color: variable.$facebook;
    }
    &.twitter{
        background-color: variable.$twitter;
    }
    &.linkedin{
        background-color: variable.$linkedin;
    }
    &.google-plus{
        background-color: variable.$google-plus;
    }
    .close{
        &:hover{
            opacity: 1!important;
            color: bs-custom.$white!important;
        }
		
    }
}
.alert .btn-close{
	background:unset;
	color:inherit;
}


// Alert left icon

.left-icon-big{
    .alert-left-icon-big{
        align-self: center;
        margin-right: 0.9375rem;
        i{
            font-size: 2.1875rem;   
			line-height: 1;
        }
		
        // &.primary{
        //     background: $primary;
        //     color: $white;
        // }
    }
    .media-body{
        // margin-left: 6.25rem;
    }
}
[direction="rtl"]{
	.left-icon-big .alert-left-icon-big,
	.alert-social .alert-social-icon{
		margin-right: 0;
		margin-left: 0.9375rem;
	}
}





