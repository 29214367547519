@use "../abstracts/bs-custom";
@use "../abstracts/mixin";
@use "../abstracts/variable";

.card{ 
	&.deliver-order{
		.border-right{
			@include mixin.respond ('phone'){
				border-right:unset;
			}
			
		}
		.address-bx{
			p{
				line-height:1.5rem
			}
		}
	}
	.dliver-order-bx{
		img{
			width:3rem;
			height:3rem;
			border-radius:variable.$radius;
			object-fit:cover;
		}
	}
}
.b-right{
	border-right:1px solid bs-custom.$border-color;
	
	@include mixin.custommq ($max:1600px){
		border-right:unset;
	}
	
	@include mixin.respond ('laptop'){
		border-right:unset;
	}
	&.style-1{
		border-right:1px solid variable.$border;
			@include mixin.respond ('phone'){
			border-right:unset;
		} 
	}
}
.accordion-header{
	h6,h4{
		color:bs-custom.$white;
	}
	p{
		color:bs-custom.$white;
	}
	
	.dev-btn{
		background-color:rgba(255,255,255,0.7);
	}
	
	&.collapsed{
		h6,h4{
			color:bs-custom.$headings-color;
			
		}
		p{
			color:bs-custom.$body-color!important;
		}
		.dev-btn{
			    background: var(--rgba-primary-1);
				border-color: var(--primary);
			&:hover{
				color:var(--primary);
			}
		}
		
	}
}
.order-accord{
	.accordion-button{
		h6,h4{
			color:bs-custom.$white;
		}
		p{
			color:bs-custom.$white;
		}
		
		.dev-btn{
			background-color:rgba(255,255,255,0.7);
		}
		&.collapsed{
			h6,h4{
				color:bs-custom.$headings-color;			
			}
			p{
				color:bs-custom.$body-color!important;
			}
			.dev-btn{
					background: var(--rgba-primary-1);
					border-color: var(--primary);
				&:hover{
					color:var(--primary);
				}
			}
			
		}
	}
	
}
.deliver-drop{
	.deliver-drop-toggle{
		.drop-toggle-icon{
			position: absolute;
			right: 15px;
			color: #eee;
		}
		&.style2{
			.drop-toggle-icon{
				position: absolute;
				right: 15px;
				color: #eee;
			}	
		}
	}
}