@use "../../abstracts/bs-custom";
@use "../../abstracts/variable";

.tdl-holder {
  margin: 0 auto;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  li {
    background-color: bs-custom.$white;
    border-bottom: 0.0625rem solid variable.$border;
    // color: #252525;
    list-style: none none;
    margin: 0;
    padding: 0;
    span {
      margin-left: 2.1875rem;
      // color: $l-ctd;
      font-size: 1rem;
      // font-weight: 600;
      vertical-align: middle;
      transition: all 0.2s linear;

      @at-root [direction="rtl"] #{&} {
        margin-left: auto;
        margin-right: 2.1875rem;
      }
    }
  }
  label {
    cursor: pointer;
    display: block;
    line-height: 3.125rem;
    padding-left: 1.5rem;
    position: relative;
    margin: 0 !important;

    @at-root [direction="rtl"] #{&} {
      padding-left: 0;
      padding-right: 1.5rem;
    }

    &:hover {
      background-color: #eef1f6;
      color: bs-custom.$dark;

      

      a {
        color: bs-custom.$danger;
      }
      
    }

    a {
      color: bs-custom.$white;
      display: inline-block;
      line-height: normal;
      height: 100%;
      text-align: center;
      text-decoration: none;
      width: 3.125rem;
      -webkit-transition: all .2s linear;
      -moz-transition: all .2s linear;
      -o-transition: all .2s linear;
      transition: all 0.2s linear;
      padding: 1.125rem 0rem;
      font-size: 1.125rem;
      position: absolute;
      right: 0;

      @at-root [direction="rtl"] #{&} {
        right: auto;
        left: 0;
      }
    }
  }
  input {
    &[type=checkbox] {
      cursor: pointer;
      opacity: 0;
      position: absolute;
      + i {
        background-color: #fff;
        border: 0.0625rem solid #e7e7e7;
        display: block;
        height: 1.25rem;
        position: absolute;
        top: 0.9375rem;
        width: 1.25rem;
        z-index: 1;
        border-radius: 0rem;
		font-size: 0.6875rem;
		border-radius: 0.25rem;
      }
      &:checked {

        + i {
          background: var(--primary);
          border-color: transparent;
		  
        }

        + i::after {
          content: "\f00c";
          font-family: 'fontAwesome';
          display: block;
          left: 0.125rem;
          position: absolute;
          top: -0.875rem;
          z-index: 2;
          color: bs-custom.$white;
        }
        ~ span {
          text-decoration: line-through;
          position: relative;
        }
      }
    }
    &[type=text] {
      background-color: bs-custom.$white;
      height: 3.125rem;
      margin-top: 1.8125rem;
      border-radius: 0.1875rem;

      &::placeholder {
        color: bs-custom.$dark;
       }
    }
  }
}