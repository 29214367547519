@use "sass:color";
@use "sass:math";
@use "functions";
@use "mixins/alert";
@use "mixins/border-radius";
@use "variables";

//
// Base styles
//

.alert {
  position: relative;
  padding: variables.$alert-padding-y variables.$alert-padding-x;
  margin-bottom: variables.$alert-margin-bottom;
  border: variables.$alert-border-width solid transparent;
  @include border-radius.border-radius(variables.$alert-border-radius);
}

// Headings for larger alerts
.alert-heading {
  // Specified to prevent conflicts of changing $headings-color
  color: inherit;
}

// Provide class for links that match alerts
.alert-link {
  font-weight: variables.$alert-link-font-weight;
}


// Dismissible alerts
//
// Expand the right padding and account for the close button's positioning.

.alert-dismissible {
  padding-right: variables.$alert-dismissible-padding-r;

  // Adjust close link position
  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: variables.$stretched-link-z-index + 1;
    padding: variables.$alert-padding-y * 1.25 variables.$alert-padding-x;
  }
}


// scss-docs-start alert-modifiers
// Generate contextual modifier classes for colorizing the alert.

@each $state, $value in variables.$theme-colors {
  $alert-background: functions.shift-color($value, variables.$alert-bg-scale);
  $alert-border: functions.shift-color($value, variables.$alert-border-scale);
  $alert-color: functions.shift-color($value, variables.$alert-color-scale);
  @if (functions.contrast-ratio($alert-background, $alert-color) < variables.$min-contrast-ratio) {
    $alert-color: color.mix($value, functions.color-contrast($alert-background), math.abs(variables.$alert-color-scale));
  }
  .alert-#{$state} {
    @include alert.alert-variant($alert-background, $alert-border, $alert-color);
  }
}
// scss-docs-end alert-modifiers
