@use "../../abstracts/bs-custom";
@use "../../abstracts/variable";


[data-theme-version="transparent"] {
    .dropdown-menu {
        background-color: variable.$dark-card;
    }

    .dropdown-item {
        &:hover, &:focus {
            background-color: variable.$d-bg;
            color: bs-custom.$white;
        }
    }

    .form-control {
        background-color: variable.$d-bg;
        border-color: variable.$d-border;
        color: bs-custom.$white;
    }
    
    .card {
        background-color: rgba(0,0,0,0.15);
    }

}