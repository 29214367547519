@use "../../abstracts/bs-custom";
@use "../../abstracts/variable";


.select2-container {
    width: 100% !important;
}

.select2-container--default .select2-selection--single {
    border-radius: variable.$radius;
    //border: 0.0625rem solid $light;
	border: 0.0625rem solid bs-custom.$border-color;
    height: 3rem;
    background: bs-custom.$white;
	@at-root [data-theme-version="dark"] & {
        background:variable.$d-bg;
		border-color:variable.$d-border;
    }
		
		
    &:hover,&:focus,&.active{
        box-shadow: none;
    }
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 3rem;
	color:bs-custom.$body-color;
	padding-left: 0.9375rem;
	min-height: 3rem;
}

.select2-container--default .select2-selection--multiple {
    border-color: variable.$border;
    border-radius: 0;
	
}

.select2-dropdown {
    border-radius: 0;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: var(--primary);
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
    border-color: #c8c8c8;
    background: bs-custom.$white;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
    top: 0.75rem;
    right: 0.9375rem;
}
.select2-container .select2-selection--multiple{
	min-height: 3rem;
	color:bs-custom.$body-color;
	border-radius: variable.$radius;
	border: 0.0625rem solid bs-custom.$border-color;
	@at-root [data-theme-version="dark"] & {
        background:variable.$d-bg;
		border-color:variable.$d-border;
    }
}
.select2-search--dropdown .select2-search__field{
	@at-root [data-theme-version="dark"] & {
        background:variable.$dark-card;
		border-color:variable.$d-border;
    }
}
.select2-dropdown{
	border-color: bs-custom.$light;
	@at-root [data-theme-version="dark"] & {
        background:variable.$d-bg;
		border-color:variable.$d-border;
    }
}
.swal2-popup .swal2-content{
	color:bs-custom.$body-color;
}
























