@use "../abstracts/bs-custom";
@use "../abstracts/mixin";
@use "../abstracts/variable";

.dashboard-select{
	&.style-1{
		background: transparent;
		border-color:#A098AE;
		font-weight: 500;
		color:bs-custom.$headings-color;
		margin: 0 1rem;
		
		&:after{
			border-color:#A098AE;
			right:0;

		}
		@include mixin.respond ('phone'){
			height: 2.5rem;
			padding-left: .75rem;
			line-height: 2.8;
			font-size: .85rem;
		}
	}
}
.notification-tab{
		background-color:#F5F5F5;
		overflow:hidden;
		border-radius:variable.$radius;
	.nav-link{
		border-radius:unset;
		color:bs-custom.$body-color;
	}
}
.notification-select{
	.notification-drop-select{
		position: absolute;
		right: 30px;
		font-size: 15px;
		color: #eee;
	}
}
.notification-dropdown{
	width: 3rem;
    height: 3rem;
    background: var(--rgba-primary-1);
    display: inline-block;
    text-align: center;
    line-height: 3rem;
    border-radius: variable.$radius;
	.dropdown-item{
		line-height:1.5;
	}
	
}
.timeline-active{
	.badge-xl{
		padding: 0.25rem 0.625rem;
	}
	li{
		position:relative;
		padding:1.25rem 0;
		.time{
			padding: 0.5rem 0rem;
			background:bs-custom.$white;
			display: block;
			position: relative;
			z-index: 1;
			color:#A098AE;
			font-size: 0.875rem;
			@include mixin.respond ('phone'){
				font-size:.75rem!important;
			}
		}
		&:before{
			top: 4.188rem;
			bottom: 0;
			position: absolute;
			content: " ";
			width: 0.125rem;
			height: calc(100% - 1.875rem);
			left: 1.4625rem;
			margin-right: -0.0938rem;
			background: #EEEE;
		}
		&:last-child{
			&:before{
				content:none;
			}
		}
		@include mixin.respond ('phone'){
			padding: 0.5rem 0;
			&:before{
				top: 3.5rem;

			}
		}		
		
	}
	.panel{
		margin-left:1.875rem;
		flex: 1;
		
		.timeline-panel{
			
		}
		strong{
			color:bs-custom.$headings-color;
		}
		
	}
	.modulel{
		display: inline-flex;
		@include mixin.respond ('phone'){
			padding: 0.5rem 0.5rem;
			&>div{
				align-items:center;
			}
			
		}
		.sub-module{
			background: #f9f9f9;
			padding: 0.8125rem 1.0625rem;
			border-radius: 0.625rem;
			margin: 1.0625rem 0;
			@include mixin.respond ('phone'){
				margin: 0.4625rem 0;
			}
			h4{
				line-height:1;
				font-size:.75rem!important;
			}
			span{
				font-size:.7rem;
			}
			&:hover{
				background:var(--primary);
				span,h4{
					color:bs-custom.$white;
				}
				span{
					svg{
						path{
							fill:var(--primary);
						}
					}
				}
			}
		}
	}
	&.style-4{
		
		li{
			padding: 0.375rem 0;
			&:before{
				content:none;
			}
			.time-1{
				color:#A098AE;
			}
			&:hover{
				.timeline-panel{
					h4{
						color:var(--primary);
					}
				}
			}
		}
		.panel{
			display: flex;
			align-items: center;
			.line-color{
				width:0.25rem;
				height:65%;
				border-radius:4px;
				position: absolute;
			}
			.timeline-panel{
				margin-left: 1.25rem;
				display: block;
			}
		}
	}
	
}
.widget-timeline{
	.side-border{
		position:relative;
		&:after{
			position: absolute;
			content: "";
			width: 100%;
			border-top:0.0625rem solid #DBDBDB;
			top: 0.75rem;
			left: 0;
		}
		h4{
			background: white;
			position: relative;
			z-index: 2;
			padding-right: 1.25rem;
		}
		.custom-dropdown{
			background: white;
			position: relative;
			z-index: 2;
			padding-left: 1.25rem;
			margin-top: -7px;
		}
	}
}
.course-details-tab{
	.nav{
		.nav-link{
			background-color:#F5F5F5;
			border-radius:variable.$radius;
			&.active{
				background-color:var(--primary);
			}
		}
	}
	.timeline-active{
		svg{
			width:1rem;
			height:1rem;
		}
	}
}