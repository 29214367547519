@use "sass:map";
@use "../mixins/breakpoints";
@use "../variables";

// Shorthand

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: variables.$zindex-fixed;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: variables.$zindex-fixed;
}

// Responsive sticky top
@each $breakpoint in map.keys(variables.$grid-breakpoints) {
  @include breakpoints.media-breakpoint-up($breakpoint) {
    $infix: breakpoints.breakpoint-infix($breakpoint, variables.$grid-breakpoints);

    .sticky#{$infix}-top {
      position: sticky;
      top: 0;
      z-index: variables.$zindex-sticky;
    }
  }
}
