@use "../mixins/transition";
@use "../variables";

.form-floating {
  position: relative;

  > .form-control,
  > .form-select {
    height: variables.$form-floating-height;
    padding: variables.$form-floating-padding-y variables.$form-floating-padding-x;
  }

  > label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%; // allow textareas
    padding: variables.$form-floating-padding-y variables.$form-floating-padding-x;
    pointer-events: none;
    border: variables.$input-border-width solid transparent; // Required for aligning label's text with the input as it affects inner box model
    transform-origin: 0 0;
    @include transition.transition(variables.$form-floating-transition);
  }

  // stylelint-disable no-duplicate-selectors
  > .form-control {
    &::placeholder {
      color: transparent;
    }

    &:focus,
    &:not(:placeholder-shown) {
      padding-top: variables.$form-floating-input-padding-t;
      padding-bottom: variables.$form-floating-input-padding-b;
    }
    // Duplicated because `:-webkit-autofill` invalidates other selectors when grouped
    &:-webkit-autofill {
      padding-top: variables.$form-floating-input-padding-t;
      padding-bottom: variables.$form-floating-input-padding-b;
    }
  }

  > .form-select {
    padding-top: variables.$form-floating-input-padding-t;
    padding-bottom: variables.$form-floating-input-padding-b;
  }

  > .form-control:focus,
  > .form-control:not(:placeholder-shown),
  > .form-select {
    ~ label {
      opacity: variables.$form-floating-label-opacity;
      transform: variables.$form-floating-label-transform;
    }
  }
  // Duplicated because `:-webkit-autofill` invalidates other selectors when grouped
  > .form-control:-webkit-autofill {
    ~ label {
      opacity: variables.$form-floating-label-opacity;
      transform: variables.$form-floating-label-transform;
    }
  }
  // stylelint-enable no-duplicate-selectors
}
