@use "../../../bootstrap-v4-rtl/scss/mixins/gradients";
@use "../functions";

@mixin alert-variant($background, $border, $color) {
  color: $color;
  @include gradients.gradient-bg($background);
  border-color: $border;

  .alert-link {
    color: functions.shade-color($color, 20%);
  }
}
