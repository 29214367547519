@use "mixins/border-radius";
@use "mixins/gradients";
@use "variables";
@use "vendor/rfs";

// Base class
//
// Requires one of the contextual, color modifier classes for `color` and
// `background-color`.

.badge {
  display: inline-block;
  padding: variables.$badge-padding-y variables.$badge-padding-x;
  @include rfs.font-size(variables.$badge-font-size);
  font-weight: variables.$badge-font-weight;
  line-height: 1;
  color: variables.$badge-color;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  @include border-radius.border-radius(variables.$badge-border-radius);
  @include gradients.gradient-bg();

  // Empty badges collapse automatically
  &:empty {
    display: none;
  }
}

// Quick fix for badges in buttons
.btn .badge {
  position: relative;
  top: -1px;
}
