@use "functions";
@use "mixins/border-radius";
@use "variables";

// transparent background and border properties included for button version.
// iOS requires the button element instead of an anchor tag.
// If you want the anchor version, it requires `href="#"`.
// See https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile

.btn-close {
  box-sizing: content-box;
  width: variables.$btn-close-width;
  height: variables.$btn-close-height;
  padding: variables.$btn-close-padding-y variables.$btn-close-padding-x;
  color: variables.$btn-close-color;
  background: transparent functions.escape-svg(variables.$btn-close-bg) center / variables.$btn-close-width auto no-repeat; // include transparent for button elements
  border: 0; // for button elements
  @include border-radius.border-radius();
  opacity: variables.$btn-close-opacity;

  // Override <a>'s hover style
  &:hover {
    color: variables.$btn-close-color;
    text-decoration: none;
    opacity: variables.$btn-close-hover-opacity;
  }

  &:focus {
    outline: 0;
    box-shadow: variables.$btn-close-focus-shadow;
    opacity: variables.$btn-close-focus-opacity;
  }

  &:disabled,
  &.disabled {
    pointer-events: none;
    user-select: none;
    opacity: variables.$btn-close-disabled-opacity;
  }
}

.btn-close-white {
  filter: variables.$btn-close-white-filter;
}
