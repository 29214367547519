@use "sass:color";
@use "../../abstracts/bs-custom";
@use "../../abstracts/mixin";
@use "../../abstracts/variable";


// Basic Input
.form-control[type="file"]{
    line-height: 34px;
    padding-left: 12px;
	
	@include mixin.custommq ($max:1600px){
		line-height: 30px;
		padding-left: 11px;

	}
	@include mixin.respond ('laptop'){
		padding-left:10px;
		
	}
}
.form-control-sm[type="file"]{
    line-height: 32px;
    padding: 4px;
    padding-left: 8px;
	
	@include mixin.custommq ($max:1600px){
		line-height: 25px;
		padding: 4px;
		padding-left: 7px;
	}
	
	
}
.form-control-lg[type="file"]{
	line-height:42px;
	padding-left:10px;
	
	@include mixin.custommq($max: 1600px){
		line-height:36px;
	}
	@include mixin.respond ('tab-land'){
		line-height:37px;
	}
	
	
}

.form-select{
	height:3rem;
	border-radius:variable.$radius;
	border-color:bs-custom.$border-color;
	
	&:focus{
		box-shadow:unset;
		border-color:unset;
	}
}
.input-group-sm > .form-control,.input-group-sm > .input-group-text{
	height:2.188rem;
	border-radius:variable.$radius;
	overflow:hidden;
}
.input-group-lg > .form-control,.input-group-lg > .input-group-text{
	height:3.625rem;
	border-radius:variable.$radius;
	overflow:hidden;
}

.form-control{
    background: #fff;
    color: #6e6e6e;
    line-height: 1.4;
    font-size: 1rem;
    height: 3rem;
	border-radius:variable.$radius;
	border-color:bs-custom.$border-color;
	padding:0.375rem 1rem;
    &:hover,&:focus,&.active{
        box-shadow: none;
        background: bs-custom.$white;
        color: bs-custom.$dark;
    }
	&:focus{
		border-color:var(--primary);
	}
	&.solid{
		background:bs-custom.$body-bg;
		border-color:#ececec;
		color:#000;
		&[type="file"]{
			padding: 6px 10px;
		}
	}
}


.input-rounded {
    border-radius: 6.25rem;
}


// Input Color
[data-theme-version="light"],
[data-theme-version="dark"] {
	.input-primary{
		.form-control{
			border-color:var(--primary);
		}	
		.input-group-text{
			background-color:var(--primary);
			color:bs-custom.$white;
		}
	}
	.input-danger{
		.form-control{
			border-color:bs-custom.$danger;
		}	
		.input-group-text{
			background-color:bs-custom.$danger;
			color:bs-custom.$white;
		}
	}
	.input-info{
		.form-control{
			border-color:bs-custom.$info;
		}	
		.input-group-text{
			background-color:bs-custom.$info;
			color:bs-custom.$white;
		}
	}
	.input-success{
		.form-control{
			border-color:bs-custom.$success;
		}	
		.input-group-text{
			background-color:bs-custom.$success;
			color:bs-custom.$white;
		}
	}
	.input-warning{
		.form-control{
			border-color:bs-custom.$warning;
		}	
		.input-group-text{
			background-color:bs-custom.$warning;
			color:bs-custom.$white;
		}
	}
}
// Input Outline
[data-theme-version="light"],
[data-theme-version="dark"] {
	.input-primary-o{
		.form-control{
			border-color:var(--primary);
			
		}	
		.input-group-text{
			background-color:transparent;
			border-color:var(--primary);
			color:var(--primary);
		}
	}
	.input-danger-o{
		.form-control{
			border-color:bs-custom.$danger;
		}	
		.input-group-text{
			background-color:transparent;
			border-color:bs-custom.$danger;
			color:bs-custom.$danger;
		}
	}
	.input-info-o{
		.form-control{
			border-color:bs-custom.$info;
		}	
		.input-group-text{
			background-color:transparent;
			border-color:bs-custom.$info;
			color:bs-custom.$info;
		}
	}
	.input-success-o{
		.form-control{
			border-color:bs-custom.$success;
		}	
		.input-group-text{
			background-color:transparent;
			border-color:bs-custom.$success;
			color:bs-custom.$success;
		}
	}
	.input-warning-o{
		.form-control{
			border-color:bs-custom.$warning;
		}	
		.input-group-text{
			background-color:transparent;
			border-color:bs-custom.$warning;
			color:bs-custom.$warning;
		}
	}
}

// Input Size/
// .form-control{
    // min-height: 2.5rem;
// }
// .form-control-sm{
//     min-height: 2.25rem;
// }
// .form-control-lg{
//     min-height: 3.25rem;
// }



// Input Group
.input-group-text{
    min-width: 3.125rem;
	justify-content: center;
	border-radius: 0.5rem;
}
.form-file-label{
    height: 2.5rem;
	padding: 0.5rem 0.75rem;
}
.input-group-prepend .btn,
.input-group-append .btn {
    z-index: 0;
}
.custom-select{
    background: none;
    // height: 2.8125rem;
    border-color: variable.$border;
    color: bs-custom.$dark;
    // border-radius: 0rem
    &:focus{
        box-shadow: none;
        border-color: var(--primary);
        color: var(--primary);
    }
}
.form-file-label{
    // height: 2.8125rem;
    // border-radius: 0rem
	background: #656C73;
	white-space: nowrap;
	color: bs-custom.$white;
	@at-root [data-theme-version="dark"] & {
		background: variable.$d-border;
		border-color: variable.$d-border;
		color: bs-custom.$body-color;
	}
}

.custom_file_input {
    .input-group-prepend, 
    .input-group-append {
        // height: 2.8125rem;
    }

    .form-file-label::after {
        height: 100%;
        // line-height: 2.4;
    }
}


.form-control:disabled,
 .form-control[readonly] {
    background: bs-custom.$white;
    opacity: 1;
}

.form-file{
	border: 0.0625rem solid variable.$border;
    background: bs-custom.$white;
	@at-root [data-theme-version="dark"] & {
        background: variable.$d-bg;
		border-color: variable.$d-border;
    }
}
.input-group{
	> .form-control-plaintext,
	  > .form-select,
	  > .form-file {
		position: relative; // For focus state's z-index
		flex: 1 1 auto;
		// Add width 1% and flex-basis auto to ensure that button will not wrap out
		// the column. Applies to IE Edge+ and Firefox. Chrome does not require this.
		width: 1%;
		margin-bottom: 0;

	  }
	/* &> .form-file {
		display: flex;
		align-items: center;

		&:not(:last-child) .form-file-label,
		&:not(:last-child) .form-file-label::after { @include border-right-radius(0); }
		&:not(:first-child) .form-file-label { @include border-left-radius(0); }
	  } */
}


.select2-container--default 
.select2-selection--multiple
 .select2-selection__choice{
	border-radius:variable.$radius;
	margin-top:11px;
}

.form-file{
	.form-control{
		margin:0;
		border-radius:0;
		border:0;
		height:auto;
	}
}
.dlab-Custom-select{
	border-radius:0.5rem;
}
#validationCustomUsername{
	border-radius: 0 0.5rem 0.5rem 0;
}
.show-pass{
	border-radius: 0 0.5rem 0.5rem 0!important;
}
.form-file{
	.form-control{
		margin:0;
		border-radius:0;
		border:0;
		height:auto;
	}
}
.input-group{
	.input-group-text + .form-file{
		border-radius:0 0.5rem 0.5rem 0;
		.form-control{
			border-radius: inherit;
		}
	}
}

.custom_file_input{
	.form-file{
		border-radius:0.5rem;
		.form-control{
			margin:0;
			border:0;
			line-height: 2.125rem;
			border-radius: inherit;
			font-size: 16px;
			font-weight: 600;
		}
	}
	.form-control{
		padding-left: 12px;
		line-height: 45px;
	}
}
#username{
	border-radius:0 0.5rem 0.5rem 0;
}
.form-control-lg{
	min-height: 3.75rem;
	padding: 0.5rem 1.1rem;
    font-size: 1.09375rem;
    border-radius: 0.5rem;
	height: calc(1.5em + 1rem + 2px);
}
.form-control-sm{
	min-height:2.5rem;
   padding: 0.25rem 1.1rem;
    font-size: 0.76563rem;
    border-radius: 0.5rem;
	height: calc(1.5em + 0.5rem + 2px);
}
.ck.ck-editor__main>.ck-editor__editable{
	background:color.adjust(bs-custom.$dark, $lightness: 55%)!important;
}
.modal-grid{
	background:bs-custom.$body-bg;
}
.nice-select.wide.form-control-lg{
	line-height: 2.7125rem;
}
.nice-select.wide.form-control-sm{
	line-height: 1.8125rem;
}
.inline-check{
	line-height: 1.8rem!important;
}
.form-check{
	font-size: 14px;
    font-weight: 600;
}
.input-full{
	border:0;
}
.border-1{
	border-radius: 0.5rem 0 0 0.5rem!important;
}
.border3{
	border-radius:0 0.5rem 0.5rem 0!important;
}
.custome-input{
	.input-group-text{
		border-color:transparent!important;
	}
}
.dlab-select{
	border-top-left-radius:variable.$radius!important;
	border-bottom-left-radius:variable.$radius!important;
}