@use "../functions";
@use "../variables";
@use "../vendor/rfs";

//
// Labels
//

.form-label {
  margin-bottom: variables.$form-label-margin-bottom;
  @include rfs.font-size(variables.$form-label-font-size);
  font-style: variables.$form-label-font-style;
  font-weight: variables.$form-label-font-weight;
  color: variables.$form-label-color;
}

// For use with horizontal and inline forms, when you need the label (or legend)
// text to align with the form controls.
.col-form-label {
  padding-top: functions.add(variables.$input-padding-y, variables.$input-border-width);
  padding-bottom: functions.add(variables.$input-padding-y, variables.$input-border-width);
  margin-bottom: 0; // Override the `<legend>` default
  @include rfs.font-size(inherit); // Override the `<legend>` default
  font-style: variables.$form-label-font-style;
  font-weight: variables.$form-label-font-weight;
  line-height: variables.$input-line-height;
  color: variables.$form-label-color;
}

.col-form-label-lg {
  padding-top: functions.add(variables.$input-padding-y-lg, variables.$input-border-width);
  padding-bottom: functions.add(variables.$input-padding-y-lg, variables.$input-border-width);
  @include rfs.font-size(variables.$input-font-size-lg);
}

.col-form-label-sm {
  padding-top: functions.add(variables.$input-padding-y-sm, variables.$input-border-width);
  padding-bottom: functions.add(variables.$input-padding-y-sm, variables.$input-border-width);
  @include rfs.font-size(variables.$input-font-size-sm);
}
