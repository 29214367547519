.tox .tox-statusbar {
  display: none !important;
}

.truncate-announcement {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}

.announcements-table-item td:first-of-type {
  width: 150px;
}

.announcements-table-item td:nth-of-type(2) {
  width: 40%;
}

@media (max-width: 1350px) {
  .announcements-table-item td:nth-of-type(2) {
    width: 35%;
  }
}

@media (max-width: 1275px) {
  .announcements-table-item td:first-of-type {
    width: 120px;
  }

  .announcements-table-item td:nth-of-type(2) {
    width: 32%;
  }
}

@media (max-width: 1150px) {
  .announcements-table-item td:first-of-type {
    width: 100px;
  }

  .announcements-table-item td:nth-of-type(2) {
    width: 30%;
  }
}

.announcement-image-selector img {
  width: 250px;
  margin: 0 auto;
  display: block;
}

.announcement-image-selector input {
  display: block;
  margin: 1rem 0;
}

.announcement-modal-image {
  width: 250px;
  display: block;
  margin: 1rem auto;
}

.announcement_table_item_image img {
  width: 100%;
  height: auto;
}
