@use "../../abstracts/bs-custom";
@use "../../abstracts/variable";

[data-theme-version="dark"] {
	.nav-header{
		border-color:variable.$d-border;
	}
	&[data-sidebar-position="fixed"][data-layout="vertical"]{
	}	
  

    .nav-control {
        color: bs-custom.$white;
    }

    .brand-logo {
        color: bs-custom.$white;

        &:hover {
            color: bs-custom.$white;
        }
    }
	
	.svg-title-path{
		fill:bs-custom.$white;
	}
}