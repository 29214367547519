@use "mixins/transition";
@use "variables";

.fade {
  @include transition.transition(variables.$transition-fade);

  &:not(.show) {
    opacity: 0;
  }
}

.collapse {
  &:not(.show) {
    display: none;
  }
}

.collapsing {
  height: 0;
  overflow: hidden;
  @include transition.transition(variables.$transition-collapse);
}
