@use "../../abstracts/variable";
@use "../../abstracts/bs-custom";
@use "../../abstracts/maps";




[data-theme-version="dark"] {
    background: variable.$d-bg;
    color: variable.$d-ctl;
	
	--nav-headbg: #FC8019;
	--sidebar-bg : #{variable.$d-bg};
	--headerbg: #FC8019;
	
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: bs-custom.$white;
    }
    a.link {
        color: variable.$d-ctd;
    }
    a.link:focus,
    a.link:hover {
        color: bs-custom.$info;
    }

    a {
        &:hover {
            color: bs-custom.$white;
        }
    }
	.dlabnav:before{
		background:var(--sidebar-bg);
	}
	
    //Generating border classess
    @each $value in maps.$borders {
        @if $value == "" {
            .border {
                border: 0.0625rem solid variable.$d-border !important;
            }
        } @else {
            .border-#{$value} {
                border-#{$value}: 0.0625rem solid variable.$d-border !important;
            }
        }
    }


}