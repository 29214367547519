@use "../functions";
@use "../variables";

@each $color, $value in variables.$theme-colors {
  .link-#{$color} {
    color: $value;

    @if variables.$link-shade-percentage != 0 {
      &:hover,
      &:focus {
        color: if(functions.color-contrast($value) == variables.$color-contrast-light, functions.shade-color($value, variables.$link-shade-percentage), functions.tint-color($value, variables.$link-shade-percentage));
      }
    }
  }
}
