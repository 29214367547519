@use "mixins/gradients";
@use "mixins/lists";
@use "mixins/pagination";
@use "mixins/transition";
@use "variables";

.pagination {
  display: flex;
  @include lists.list-unstyled();
}

.page-link {
  position: relative;
  display: block;
  color: variables.$pagination-color;
  text-decoration: if(variables.$link-decoration == none, null, none);
  background-color: variables.$pagination-bg;
  border: variables.$pagination-border-width solid variables.$pagination-border-color;
  @include transition.transition(variables.$pagination-transition);

  &:hover {
    z-index: 2;
    color: variables.$pagination-hover-color;
    text-decoration: if(variables.$link-hover-decoration == underline, none, null);
    background-color: variables.$pagination-hover-bg;
    border-color: variables.$pagination-hover-border-color;
  }

  &:focus {
    z-index: 3;
    color: variables.$pagination-focus-color;
    background-color: variables.$pagination-focus-bg;
    outline: variables.$pagination-focus-outline;
    box-shadow: variables.$pagination-focus-box-shadow;
  }
}

.page-item {
  &:not(:first-child) .page-link {
    margin-left: variables.$pagination-margin-start;
  }

  &.active .page-link {
    z-index: 3;
    color: variables.$pagination-active-color;
    @include gradients.gradient-bg(variables.$pagination-active-bg);
    border-color: variables.$pagination-active-border-color;
  }

  &.disabled .page-link {
    color: variables.$pagination-disabled-color;
    pointer-events: none;
    background-color: variables.$pagination-disabled-bg;
    border-color: variables.$pagination-disabled-border-color;
  }
}


//
// Sizing
//
@include pagination.pagination-size(variables.$pagination-padding-y, variables.$pagination-padding-x, null, variables.$pagination-border-radius);

.pagination-lg {
  @include pagination.pagination-size(variables.$pagination-padding-y-lg, variables.$pagination-padding-x-lg, variables.$font-size-lg, variables.$pagination-border-radius-lg);
}

.pagination-sm {
  @include pagination.pagination-size(variables.$pagination-padding-y-sm, variables.$pagination-padding-x-sm, variables.$font-size-sm, variables.$pagination-border-radius-sm);
}
