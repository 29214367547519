@use "sass:color";
@use "sass:math";
@use "../functions";
@use "../variables";

// scss-docs-start table-variant
@mixin table-variant($state, $background) {
  .table-#{$state} {
    $color: functions.color-contrast(functions.opaque(variables.$body-bg, $background));
    $hover-bg: color.mix($color, $background, math.percentage(variables.$table-hover-bg-factor));
    $striped-bg: color.mix($color, $background, math.percentage(variables.$table-striped-bg-factor));
    $active-bg: color.mix($color, $background, math.percentage(variables.$table-active-bg-factor));

    --#{variables.$variable-prefix}table-bg: #{$background};
    --#{variables.$variable-prefix}table-striped-bg: #{$striped-bg};
    --#{variables.$variable-prefix}table-striped-color: #{functions.color-contrast($striped-bg)};
    --#{variables.$variable-prefix}table-active-bg: #{$active-bg};
    --#{variables.$variable-prefix}table-active-color: #{functions.color-contrast($active-bg)};
    --#{variables.$variable-prefix}table-hover-bg: #{$hover-bg};
    --#{variables.$variable-prefix}table-hover-color: #{functions.color-contrast($hover-bg)};

    color: $color;
    border-color: color.mix($color, $background, math.percentage(variables.$table-border-factor));
  }
}
// scss-docs-end table-variant
