@use "../../../bootstrap-v4-rtl/scss/mixins/border-radius" as border-radius2;
@use "../variables";
@use "../vendor/rfs";
@use "border-radius";

// Pagination

@mixin pagination-size($padding-y, $padding-x, $font-size, $border-radius) {
  .page-link {
    padding: $padding-y $padding-x;
    @include rfs.font-size($font-size);
  }

  .page-item {
    @if variables.$pagination-margin-start == (-(variables.$pagination-border-width)) {
      &:first-child {
        .page-link {
          @include border-radius.border-start-radius($border-radius);
        }
      }

      &:last-child {
        .page-link {
          @include border-radius.border-end-radius($border-radius);
        }
      }
    } @else {
      //Add border-radius to all pageLinks in case they have left margin
      .page-link {
        @include border-radius2.border-radius($border-radius);
      }
    }
  }
}
